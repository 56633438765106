/** @jsx jsx */
import * as React from "react"
import { jsx, Styled } from "theme-ui"

import { Icon } from "../Icon"
import { ProfilePic } from "../ProfilePic"

import { ISiteMetadata } from "../../types"

interface IBioProps {
  siteMetadata: ISiteMetadata
}

export const Bio: React.FC<IBioProps> = ({ siteMetadata }) => {
  const { title, description, config } = siteMetadata

  return (
    <Styled.div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row", "row"],
        alignItems: "center",
        color: "text",
        fontFamily: "body",
        backgroundColor: "surface",
        overflow: "hidden",
        borderRadius: 1,
        p: 3,
        mb: 3,
      }}
    >
      <Styled.div
        sx={{
          ml: 4,
          mr: 5,
          mt: [4, 2],
          mb: [4, 2],
        }}
      >
        <ProfilePic />
      </Styled.div>

      <Styled.div>
        <Styled.h2>{title}</Styled.h2>
        <Styled.p>{description}</Styled.p>
        <Styled.a
          sx={{
            display: "inline-flex",
            alignItems: "center",
            color: "text",
            mr: 2,
            ":hover": {
              color: "secondary",
            },
          }}
          href={`https://www.twitter.com/${config.twitter}`}
          target="_blank"
        >
          <Icon
            iconSize={32}
            iconPath="M21.1,6.2l-2,0.6c0.6-0.5,1.2-1.2,1.5-2l-2.4,0.8c-0.6-0.7-1.6-1.2-2.7-1.2c-2,0-3.6,1.7-3.6,3.8v1c-3-0.4-6-1.8-7.7-4
	c-0.3,0.2-0.5,1-0.5,1.6c0,1.3,0.6,2.5,1.6,3c-0.6,0-1.2,0-1.7-0.3c0,1.9,1.3,3.9,3,3.9H4.9c0.5,1.7,2,2.8,3.5,2.8
	c-1.2,1-3,1.6-4.6,1.6H2.9c1.7,1,3.6,1.7,5.7,1.7c7,0,10.7-6,10.7-11V8.1C20,7.6,20.6,6.9,21.1,6.2L21.1,6.2z"
          />
        </Styled.a>

        <Styled.a
          sx={{
            display: "inline0flex",
            alignItems: "center",
            color: "text",

            ":hover": {
              color: "secondary",
            },
          }}
          href={`https://www.linkedin.com/in/${config.github}`}
          target="_blank"
        >
          <Icon
            iconSize={32}
            iconPath="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
          />
        </Styled.a>
      </Styled.div>
    </Styled.div>
  )
}
