// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-layouts-post-tsx": () => import("./../node_modules/@pauliescanlon/gatsby-theme-gatstats/src/layouts/post.tsx" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-layouts-post-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-404-js": () => import("./../node_modules/@pauliescanlon/gatsby-theme-gatstats/src/pages/404.js" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-404-js" */),
  "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-index-mdx": () => import("./../node_modules/@pauliescanlon/gatsby-theme-gatstats/src/pages/index.mdx" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-index-mdx" */),
  "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-posts-mdx": () => import("./../node_modules/@pauliescanlon/gatsby-theme-gatstats/src/pages/posts.mdx" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-gatstats-src-pages-posts-mdx" */),
  "component---src-pages-about-massimiliano-picone-mdx": () => import("./../src/pages/about-massimiliano-picone.mdx" /* webpackChunkName: "component---src-pages-about-massimiliano-picone-mdx" */),
  "component---src-pages-quotes-mdx": () => import("./../src/pages/quotes.mdx" /* webpackChunkName: "component---src-pages-quotes-mdx" */)
}

